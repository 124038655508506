import axios from "axios";
import { BASE_HOST_ADMIN_USERS } from "../../config/config";
import axiosRequest from "../axiosReq";
import { getToken } from "../../utils/token";

class RegisteredUserService {
  getAllRegisteredUsers = (data = {}) => {
    const { pageNo = 0, pageSize = 10, searchValue = "" } = data || {};

    const req = axios.get(
      BASE_HOST_ADMIN_USERS +
        "/users/getMarketplaceUsers?pageNo=" +
        pageNo +
        "&pageSize=" +
        pageSize +
        "&identifier=" +
        searchValue,
    );

    return axiosRequest(req);
  };

  blockUnblockRegisteredUser = (data = {}) => {
    const req = axios.put(
      BASE_HOST_ADMIN_USERS +
        `/users/changeUserStatus?id=${data.id}&status=${data.status}`,
    );
    return axiosRequest(req);
  };

  exportUsers = async () => {
    const token = `bearer${getToken()}`;
    const req = await axios.get(
      BASE_HOST_ADMIN_USERS + `/users/exportUserData?authorization=` + token,
    );
    return req;
  };

  deleteRegisteredUser = (data = {}) => {
    const req = axios.delete(
      BASE_HOST_ADMIN_USERS + `/account/delete?id=${data.id}`,
      data,
    );
    return axiosRequest(req);
  };

  // METHODS TO ADD/DELETE API KEY
  addAPIKey = ({ userId, apiKey }) => {
    const req = axios.put(
      BASE_HOST_ADMIN_USERS +
        "/users/assignApiKey?userId=" +
        userId +
        "&apiKey=" +
        apiKey,
    );

    return axiosRequest(req);
  };
  deleteAPIKey = ({ userId }) => {
    const req = axios.delete(
      BASE_HOST_ADMIN_USERS + "/users/deleteApiKey/" + userId,
    );

    return axiosRequest(req);
  };
  // : METHODS TO ADD/DELETE API KEY
}

const instance = new RegisteredUserService();

export default instance;
