import * as React from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { IconButton, Menu, MenuItem } from "@mui/material";
import { styles } from "../../../styles/actionButtons";
import withStyles from "@mui/styles/withStyles";
import { useDispatch } from "react-redux";
import {
  blockUnblockRegisteredUser,
  deleteRegisteredUser,
  deleteRegisteredUserNew,
  blockUnblockRegisteredUserNew,
} from "../../../store/RegisteredUser/RegisteredUserSlice";
import AlertDialogNew from "../../../common/AlertDialog/AlertDialogNew";
const ITEM_HEIGHT = 48;
import {
  USER_STATUS,
  USER_STATUS_REVERSED,
  USER_STATUS_REVERSED_IMPROVED,
} from "../../../store/App/constants";
import {
  resendOrCancelEmailUser,
  resendPassCode,
  copyCode,
  copyInvitationLink,
  unlockUser,
  unlinkUserToManufacturer,
} from "../../../store/InviteUser/inviteUserSlice";
import { showMessage } from "../../../store/Revalu/notificationSlice";

function ActionButtons(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [action, setAction] = React.useState(null);
  const open = Boolean(anchorEl);
  const {
    classes,
    rowData = {},
    refreshPage,
    type,
    handleLinkManufacturer = () => {},
    handleOpenAddAPIKeyModal = () => {},
    handleDeleteAPIKey = () => {},
    onActionMenuOpen = () => {},
  } = props;

  const idProp = rowData?.id;
  const emailProp = rowData?.email;
  const statusProp = rowData?.status;
  const isLockedProp = rowData?.isLocked;
  const isManufacturer = rowData?.manufacturer;
  const { isApiKeyExists } = rowData;
  const dispatch = useDispatch();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    onActionMenuOpen(rowData);
  };
  const closeOptionsDiaog = () => {
    setAnchorEl(null);
  };

  const block_unblock_RegisteredUser = async (option) => {
    try {
      const data = {
        id: idProp,
        status: USER_STATUS_REVERSED_IMPROVED[option],
      };
      await dispatch(blockUnblockRegisteredUserNew(data));
      refreshPage();
    } catch (err) {
      console.error(err, "err");
    }
  };

  const handleUpdateStatusToAccountCreated = () => {
    try {
      block_unblock_RegisteredUser("ACCOUNTED_CREATED");
    } catch (err) {
      console.error(err, "err");
    }
  };

  const delete_RegisteredUser = async () => {
    try {
      const data = {
        id: idProp,
      };
      await dispatch(deleteRegisteredUserNew(data));

      refreshPage();
    } catch (err) {
      console.error(err, "err");
    }
  };

  const closeAlertDialog = () => {
    setAction(null);
  };

  const cancelAlertHanlder = () => {
    closeAlertDialog();
  };

  const confirmAlertHanlder = () => {
    if (action === "block") {
      block_unblock_RegisteredUser("Blocked");
    } else if (action === "unblock") {
      block_unblock_RegisteredUser("ACCOUNTED_CREATED");
    } else if (action === "delete") {
      delete_RegisteredUser();
    } else if (action === "unlinkManufacturer") {
      handleUnLinkManufacturer();
    } else if (action === "deleteAPIKey") {
      handleDeleteAPIKey();
    }
    closeAlertDialog();
  };

  const resendOrCancelHandler = async (action) => {
    const data = [
      {
        email: emailProp,
      },
    ];
    dispatch(resendOrCancelEmailUser(data, action));
    refreshPage();
    handleClose();
  };
  const resendCode = async () => {
    const data = { email: emailProp };
    dispatch(resendPassCode(data));
    refreshPage();
    handleClose();
  };
  const handleCopyCode = async () => {
    const data = { email: emailProp, userStatus: statusProp };
    dispatch(copyCode(data));
    refreshPage();
    handleClose();
  };
  const handleCopyInvitationLink = async () => {
    const data = { email: emailProp, userStatus: statusProp };
    dispatch(copyInvitationLink(data));
    refreshPage();
    handleClose();
  };

  const handleUnLockUser = () => {
    dispatch(unlockUser(idProp));
    refreshPage();
  };

  const handleUnLinkManufacturer = async () => {
    try {
      await dispatch(unlinkUserToManufacturer(idProp));
      refreshPage();
      dispatch(
        showMessage({ message: "Unlinked Successfully", variant: "success" }),
      );
    } catch (error) {
      dispatch(showMessage({ message: error, variant: "error" }));
    }
  };

  const alertDialogProps = {
    block: {
      hidden: !(statusProp === "ACCOUNTED_CREATED"),
      title: "Block user",
      message: `Are you sure want to block the user ${emailProp}?`,
    },
    unblock: {
      hidden: !(statusProp === "BLOCKED" || statusProp === "Blocked"),
      title: "Unblock user",
      message: `Are you sure want to unblock the user ${emailProp}?`,
    },
    delete: {
      hidden: type !== "SuperAdmin",
      title: "Delete User",
      message: `Are you sure want to delete the user ${emailProp}?`,
    },
    cancelInvite: {
      hidden: !(statusProp === "INVITATION_SENT"),
      title: "Cancel Invite",
      message: `Are you sure want to cancel the invite`,
    },
    resendInvite: {
      hidden: !(statusProp === "INVITATION_SENT"),
      title: "Resend Invite",
      message: `Are you sure want to resend the invite`,
    },
    copyInvitationLink: {
      hidden: !(
        statusProp === "INVITATION_SENT" || statusProp === "EMAIL_VERIFIED"
      ),
      title: "Copy Invitation Link",
      message: `Are you sure want to copy the invite link`,
    },
    resendCode: {
      hidden: !(statusProp === "SECURITY_CODE_SENT"),
      title: "Resend Code",
      message: `Are you sure want to resend the code`,
    },
    copyCode: {
      hidden: !(statusProp === "SECURITY_CODE_SENT"),
      title: "Copy Code",
      message: `Are you sure want to resend the code`,
    },
    unlock: {
      hidden: !(isLockedProp == "0"),
      title: "Unlock user",
      message: `Are you sure want to unlock the user`,
    },
    unlinkManufacturer: {
      hidden: !(statusProp === "ACCOUNTED_CREATED" && isManufacturer !== null),
      title: "Unlink Manufacturer",
      message: `Are you sure want to unlink manufacturer from the user`,
    },

    // delete api key
    deleteAPIKey: {
      hidden: !(statusProp === "ACCOUNTED_CREATED" && isManufacturer !== null),
      title: "Delete API Key",
      message: `Are you sure want to delete api key?`,
    },
  };

  const alertDialogButtons = {
    confirm: {
      buttonText: "Confirm",
      actionCall: confirmAlertHanlder,
    },
    cancel: {
      buttonText: "Cancel",
      actionCall: cancelAlertHanlder,
    },
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className={classes.ActionButtons}>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls="long-menu"
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
        size="large">
        <MoreVertIcon />
      </IconButton>

      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        style={{
          marginTop: 33,
          maxHeight: ITEM_HEIGHT * 7,
          width: "20ch",
        }}>
        {!alertDialogProps.block.hidden ? (
          <MenuItem
            onClick={() => {
              setAction("block");
              closeOptionsDiaog();
            }}>
            {alertDialogProps.block.title}
          </MenuItem>
        ) : null}

        {!alertDialogProps.delete.hidden ? (
          <MenuItem
            onClick={() => {
              setAction("delete");
              closeOptionsDiaog();
            }}>
            {alertDialogProps.delete.title}
          </MenuItem>
        ) : null}

        {!alertDialogProps.unblock.hidden ? (
          <MenuItem
            onClick={() => {
              setAction("unblock");
              closeOptionsDiaog();
            }}>
            {alertDialogProps.unblock.title}
          </MenuItem>
        ) : null}
        {!alertDialogProps.unlock.hidden ? (
          <MenuItem onClick={() => handleUnLockUser()}>Unlock user</MenuItem>
        ) : null}

        {!alertDialogProps.cancelInvite.hidden ? (
          <MenuItem onClick={() => resendOrCancelHandler("cancel")}>
            Cancel Invite
          </MenuItem>
        ) : null}
        {!alertDialogProps.resendInvite.hidden ? (
          <MenuItem onClick={() => resendOrCancelHandler("resend")}>
            Resend Invite
          </MenuItem>
        ) : null}
        {!alertDialogProps.resendCode.hidden ? (
          <MenuItem onClick={() => resendCode()}>Resend Code</MenuItem>
        ) : null}
        {!alertDialogProps.copyCode.hidden ? (
          <MenuItem onClick={() => handleCopyCode()}>Copy Code</MenuItem>
        ) : null}
        {!alertDialogProps.copyInvitationLink.hidden ? (
          <MenuItem onClick={() => handleCopyInvitationLink()}>
            Copy Invitation Link
          </MenuItem>
        ) : null}
        {statusProp === "ACCOUNTED_CREATED" && isManufacturer === null && (
          <MenuItem onClick={() => handleLinkManufacturer(idProp)}>
            Link Manufacturer
          </MenuItem>
        )}
        {statusProp === "SECURITY_CODE_SENT" && (
          <MenuItem onClick={handleUpdateStatusToAccountCreated}>
            Approve Email ID
          </MenuItem>
        )}

        {/* ADD/DELETE API KEY */}
        {isApiKeyExists ? (
          <MenuItem
            onClick={() => {
              handleClose();
              setAction("deleteAPIKey");
            }}>
            Delete API Key
          </MenuItem>
        ) : (
          <MenuItem
            onClick={() => {
              handleClose();
              handleOpenAddAPIKeyModal();
            }}>
            Add API Key
          </MenuItem>
        )}

        {/* : ADD/DELETE API KEY */}

        {!alertDialogProps.unlinkManufacturer.hidden ? (
          <MenuItem
            onClick={() => {
              setAction("unlinkManufacturer");
            }}>
            UnLink Manufacturer
          </MenuItem>
        ) : null}
      </Menu>

      <AlertDialogNew
        open={action}
        closeDialog={closeAlertDialog}
        buttons={alertDialogButtons}
        title={alertDialogProps[action]?.title}
        message={alertDialogProps[action]?.message}
      />
    </div>
  );
}
export default withStyles(styles)(ActionButtons);
